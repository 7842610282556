<template>
    <div class="company">
        <headLine msg='平台信息'></headLine>   
        <div class="companyInfo">
            <el-form   :model="ruleForm" :rules="rules"
                ref="ruleForm" label-width="140px" class="demo-ruleForm" label-position="right">
                <el-form-item label="平台昵称：" prop="userName">
                    <el-input clearable  v-model="ruleForm.userName" placeholder="请输入平台昵称"></el-input>
                </el-form-item>
                <el-form-item label="平台账户：" prop="phoneNum">
                    <el-input clearable  v-model="ruleForm.phoneNum" placeholder="请输入手机号作为平台登录账号"></el-input>
                </el-form-item>
                <el-form-item label="账户密码：" prop="password">
                    <el-input clearable  type="password" v-model="ruleForm.password" placeholder="请输入账户密码"></el-input>
                </el-form-item>
                <el-form-item label="招聘联系人：" prop="contactsName">
                    <el-input clearable v-model="ruleForm.contactsName" placeholder="请输入招聘联系人名称"></el-input>
                </el-form-item>
                <el-form-item label="招聘联系电话：" prop="linkPhoneNum">
                    <el-input clearable v-model="ruleForm.linkPhoneNum" placeholder="请输入招聘联系电话"></el-input>
                </el-form-item>
                <el-form-item label="美和易思对接人：" prop="linkTeacher">
                    <el-select clearable v-model="ruleForm.linkTeacher" placeholder="请选择美和易思对接人">
                        <el-option v-for="(v,i) in linkTeacherList" :key="i" 
                        :label="v.nickName" :value="v.id"></el-option>  
                    </el-select>
                </el-form-item>
                
                <div class="btnBox">  
                    <button type="button" class="btns" @click="submitForm('ruleForm')">保存</button>  
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
const iphoneReg=/^[1]([3-9])[0-9]{9}$/
// import md5 from 'js-md5'
export default {
    data() {
        var validateIphone=(rule,value, callback) => {
            if (value === '') {
            callback(new Error('请输入手机号'));
            } else if (!iphoneReg.test(value)) {
            callback(new Error('请输入正确的手机号'));
            } else {
            callback();
            }
            }
        return {
           linkTeacherList:[],
           ruleForm:{},
           comId:'',
           rules:{
                phoneNum:[{ validator: validateIphone, trigger: 'blur',required: true, },
                { validator: validateIphone, trigger: 'blur' }],
                contactsName: [{ required: true, message: '请输入招聘联系人名称', trigger: 'blur' },],
                linkPhoneNum:[{ required: true, message: '请输入招聘联系电话' },
                { validator: validateIphone, trigger: 'blur' },],
                linkTeacher:[{ required: true, message: '请选择美和易思对接人', trigger: 'blur' },],
                password:[{ required: true, message: '请输入平台账户密码', trigger: 'blur' },
                 { min: 6, max: 200, message: '密码长度不得少于6位数', trigger: 'blur' }],
                userName:  [{ required: true, message: '请输入平台昵称', trigger: 'blur' }]    
            },
            pwd:'',//原账户的加密密码
           
        }
    },
    methods:{
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) { 
                    if(this.pwd==this.ruleForm.password){
                        delete this.ruleForm.password
                    }
                    this.$htp.put_('/hr/company/update',this.ruleForm,true).then(()=>{
                        sessionStorage.setItem('nickName',this.ruleForm.userName)
                        this.getInitList()
                    })
                } else {
                    return false;
                }
            });
        },
        getInitList(){
            this.$htp.get_('/hr/company/get?companyId='+this.comId).then(res=>{
                let data=res.data
                this.pwd=data.password
                this.ruleForm={...data}
                this.ruleForm.password=data.password
            })
        },
        getLinkTeacher(){
            this.$htp.get_('/hr/linkTea/list').then(res=>{
                this.linkTeacherList=res.data
                this.getInitList()
            })
        } 
    },
    mounted(){
        this.comId=sessionStorage.getItem('id')  
        this.getLinkTeacher()      
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/companyInfo.scss';
</style>